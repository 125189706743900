/*
 * Config
 * @ Variables
 *
*/
/*calendar dropdown*/
/*
 *  Radius
*/
/*
 *  Use Defaults
*/
/*
 *  YWW base Font size
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * H8
*/
/*
 * H9
*/
/*
 * H10
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
 * H5
*/
/*
 * H6
*/
/*
 * H7
*/
/*
 * body-1
*/
/*
 * body-2
*/
/*
 * body-3
*/
/*
 * body-4
*/
/*
 * body-5
*/
/*
 * body-6
*/
/* media carousel overlay */
/*
 * H1
*/
/*
 * H2
*/
/*
 * H3
*/
/*
 * H4
*/
/*
* form placeholder variables
*/
/*
 * Base
 * @ Colors
 *
*/
/* Colors */
/* Trip Advisor Color */
/* Guest checkout Color */
/*
 * Line height font
 */
/*
 * Border Radius
 */
/*
 * Border Radius Separate
 */
/*
 * Box Shadow
 */
/*
 * Widescreen
 */
/*
 * Desktop (PC's)
 */
/*
 * Wide screen Desktop (PC's)
 */
/*
 * Extra Wide screen Desktop (PC's)
 */
/*
 * Tablet
 */
/*
  * TabletOnly
  */
/*
 * upto Tablet
 */
/*
 * Tablet2
 */
/*
 * Mobile
 */
/*
 * No Screen
 */
/*
 * Transition
    usage example
      a{
      color: gray;
      @include transition(color .3s ease);
      &:hover {
        color: black;
      }
}
 */
/*
 * Opacity
 */
/*
 * Clearfix
 */
/*
 * Configure vendor
 */
/*
 * SVG stroke and fill
 */
/*
 * Transform
 */
/**
*   Use Arrow inside the :after and :before pseudo elements
*   and change the position as per needed
*   @include arrows('right', 7px, 7px, #000);
**/
/*
 * Flex
 */
/*
 * New Design System - 2025
 *
*/
/*
* Breakpoint
* @ Variables
*
*/
/*
    i/p -
        $available-maxwidth : maximum available width
        $column-width : width of 1 column
        $col-count : column no who's width has to be calculated
    o/p -
        column width of desired column no in %
    example -
        $available-max-width = 13200 , $column-width = 12
        avl width = 1320px = 100%
        width of 1px in % = 100 *(1 / 1320px)
        1 col-width in % = col-width * width of 1px in %
        86px(col-width) in % = 86 * (100 *(1 / 1320px)) = 6.51515%
*/
/*
    o/p -
        column width of desired column no in %
    i/p -
        $breakpoint : viewport at which width needs to be calculated
        $col-count : column no who's width has to be calculated
*/
/*
    o/p -
        returns value of gutter for 1 column in % as per the viewport
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            $width: width of column w.r.t col-count
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
    o/p -
        returns
            $margin: margin-left/right for a column
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
*/
/*
    o/p -
        returns
            margin-left: left margin = width of 2 columns + gutter
    i/p -
        $breakpoint : breakpoint at which width needs to be calculated
        $col-count : value of column who's width has to be calculated
*/
/*
 * Base
 * @ Text
 *
*/
/*
 * Base
 * @ Gradients
 *
*/
/*
 * Line height font
 */
.c-tabs-with-placeholder {
  margin-top: 0;
  margin-bottom: 40px;
  min-height: 100px;
}
.c-tabs-with-placeholder h2,
.c-tabs-with-placeholder .heading-2 {
  text-align: center;
}
.c-tabs-with-placeholder .component-title {
  max-width: 1320px;
  margin: auto;
  text-align: center;
}
.c-tabs-with-placeholder .v-tabs-dropdown {
  margin: 0 auto;
  text-align: center;
  max-width: 93.3333333333%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  width: 100%;
  margin: 40px auto;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    max-width: 96.875%;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    max-width: 96.6325036603%;
  }
}
@media (min-width: 1367px) {
  .c-tabs-with-placeholder .v-tabs-dropdown {
    max-width: 1320px;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links {
  padding: 15px 18px;
  border-bottom: 4px solid #d8d8d8;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu-mobile .tabs-menu--links.is-active {
  border-color: #0073cd;
}
.c-tabs-with-placeholder .v-tabs-dropdown div.tab-menu-dropdown-container {
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  padding: 15px 18px;
  border: 1px solid #e0e0e0;
  border-radius: 0;
  height: auto;
  text-align: left;
  /* Small devices (tablets, 768px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown div.tab-menu-dropdown-container {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  position: static;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  color: #404040;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  transform: none;
  border: solid #e0e0e0;
  border-width: 0 0 0 1px;
  height: 54px;
  top: 0;
  width: 54px;
  right: 0;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot:after {
  content: "";
  border: 2px solid #404040;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
  -webkit-transform: rotate(135deg);
  -khtml-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  transform: rotate(135deg) translateX(-5px);
  margin-left: 18px;
}
.c-tabs-with-placeholder .v-tabs-dropdown.expand-dropdown .tab-menu-dropdown-carrot {
  transform: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown.expand-dropdown .tab-menu-dropdown-carrot:after {
  content: "";
  border: 2px solid #404040;
  border-bottom-width: 0;
  border-left-width: 0;
  position: absolute;
  width: 12px;
  height: 12px;
  top: calc(50% - 6px);
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  max-width: 100%;
  position: static;
  border-radius: 0;
  border: 1px solid #e0e0e0;
  border-top: 0;
  width: 95.2380952381%;
  max-width: 730.4761904762px;
  margin-left: 2.380952381%;
  margin-right: 2.380952381%;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    width: 81.5151515152%;
    max-width: 1113.496969697px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin-left: 9.2424242424%;
    border: none;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu:before {
  display: none;
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  padding: 15px 18px;
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 600;
  border-color: #e0e0e0;
  opacity: 1;
  color: #6e6e6e;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    padding: 0 0 18px;
  }
}
.c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  background-color: #f8f8f8;
  color: #404040;
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    background-color: transparent;
    border-color: #0073cd;
  }
}
.c-tabs-with-placeholder .tab-menu-dropdown-head-length {
  display: none;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links.is-active {
    z-index: 6;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links:after {
    box-shadow: none;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu .tabs-menu--links:nth-child(2) {
    z-index: 5;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget.component .tabs-menu.focus-within .tabs-menu--links:after {
    box-shadow: none;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu {
  background-color: #f0f9ff;
  color: #333;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 1.29;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
  font-weight: 700;
  line-height: 0;
  color: #0073cd;
}
@media (min-width: 768px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links span,
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links {
    color: #333;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:after {
    background: #f6f6f6;
  }
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
  background: #0073cd;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) a {
  color: #ffffff;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(1) {
    background: transparent;
    color: #333;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links:nth-child(even):after {
  background: #f0f9ff;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
  background: #0073cd;
  color: #ffffff;
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active span,
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active a {
  color: #ffffff;
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active:after {
  background: #0073cd;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-menu .tabs-menu--links.is-active {
    background: transparent;
  }
}
.c-tabs-with-placeholder.v-tabs-hotel-booking-widget .tabs-panel-container .close-tab svg use {
  stroke: #0073cd;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section {
  /* Medium devices (desktops, 1024px and up) */
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .yas-custom-radio input:checked::before {
  background-color: #0073cd;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .form-control .form-checkbox-fancy:after {
  background-color: #0073cd;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .no-annual-pass-section .btn-primary a {
  font-size: 14px;
  font-size: 0.875rem;
  padding: 10px 30px;
  font-weight: 800;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .no-annual-pass-section .btn-primary a {
  font-weight: 700;
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 95.2380952381%;
    max-width: 730.4761904762px;
    margin-left: 2.380952381%;
    margin-right: 2.380952381%;
    /* Small devices (tablets, 768px and up) */
    /* Medium devices (desktops, 1024px and up) */
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 768px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 80.6451612903%;
    max-width: 825.8064516129px;
    margin-left: 1.3440860215%;
    margin-right: 1.3440860215%;
    margin: 0 auto;
  }
}
@media only screen and (min-width: 1024px) and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section {
    width: 81.5151515152%;
    max-width: 1113.496969697px;
    margin-left: 0.9090909091%;
    margin-right: 0.9090909091%;
    margin: 0 auto;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile {
  background-color: #0073cd;
  padding: 7px 0;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe.renew-mobile a {
  font-size: 16px;
  font-size: 1rem;
  font-weight: 800;
  line-height: 20px;
  letter-spacing: 1px;
  color: #ffffff;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 800;
  line-height: 18px;
  padding: 10px 30px;
  color: #ffffff;
  transition: color 0.5s ease;
  border-color: #0073cd;
  background-color: #0073cd;
}
.lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
  font-weight: 800;
  font-size: 16px;
  font-size: 1rem;
  line-height: 20px;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a:focus, .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a:hover {
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a:focus, .c-tabs-with-placeholder .tabs-panel-container .renew-section .btn-wrapper .btn-subscribe a:hover {
    background-color: #ffffff;
    color: #0073cd;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section {
  /* < 1023px */
}
@media (min-width: 320px) and (max-width: 1024px) {
  .c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section {
    margin-bottom: 10px;
  }
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section .checkbox-section .checkbox-label {
  display: flex;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section .checkbox-section .checkbox-label input ~ .checkbox {
  display: inline-block;
  width: 26px;
  height: 26px;
  position: relative;
  right: 0;
  border: 1px solid #e0e0e0;
}
.c-tabs-with-placeholder .tabs-panel-container .renew-section .annual-pass-section .annual-pass-details .annual-pass-row .left-section .checkbox-section .checkbox-label input:checked ~ .checkbox:after {
  background-color: transparent;
  content: "";
  border: 2px solid #38bfff;
  width: 14px;
  height: 7px;
  position: absolute;
  top: 8px;
  left: 5px;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -khtml-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown div.tab-menu-dropdown-container {
  text-align: right;
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot {
  left: 0;
  right: auto;
  border-width: 0 1px 0 0;
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tab-menu-dropdown-carrot:after {
  margin: 0 18px 0 0;
  left: 17px;
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    margin-right: 9.6774193548%;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu {
    margin-right: 9.2424242424%;
  }
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
  font-weight: 600;
  text-align: right;
  /* Medium devices (desktops, 1024px and up) */
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links {
    text-align: center;
  }
}
.lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
  font-family: "Noto Kufi Arabic", "Open Sans", Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 1.43;
  font-size: 14px;
  font-size: 0.875rem;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 1024px and up) */
  /* Medium devices (desktops, 1366px and up) */
}
@media (min-width: 768px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1024px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
@media only screen and (min-width: 1366px) {
  .lang-ar .c-tabs-with-placeholder .v-tabs-dropdown .tabs-menu--links.is-active {
    font-size: 14px;
    font-size: 0.875rem;
  }
}
.lang-ar .c-tabs-with-placeholder .component-title {
  max-width: 1320px;
  margin: auto;
  text-align: center;
}